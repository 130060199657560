<template>
  <div v-cloak class="fill-height">
    <Header :menuLeft="false" />

    <v-content class="fill-height">
      <v-container fill-height fluid class="ma-0 pa-0">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="12">
            <h2>Producción</h2>
            <v-row justify="center" class="pt-4">
              <v-col cols="12" sm="6" md="4">
                <TitleButtonCard
                  title="Gestión Producción"
                  @clickButton="goToProductionPlanning()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <!-- <Footer /> -->
  </div>
</template>

<script>
import TitleButtonCard from "@/components/TitleButtonCard.vue";
// import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "manufacture-item",
  components: {
    TitleButtonCard,
    // Footer,
    Header,
  },
  data: () => ({}),
};
</script>