import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        white: "#ffffff",
        primary: "#1ca75e",
        secondary: "#888888",
        accent: "#f44336",
        error: "#c98000",
        warning: "#EED202",
        info: "#3c81f0",
        success: "#9aff96",
        logo: "#1ca75e",
      },
    },
  },
});
