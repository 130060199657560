<template>
  <div class="fill-height">
    <Header :menuLeft="false" />

    <v-content class="fill-height">
      <v-img
        gradient="rgba(255,255,255,.8), rgba(255,255,255,.8)"
        class="background"
        src="../assets/ATS.jpg"
      />
      <v-container fill-height fluid class="ma-0 pa-0">
        <v-row justify="center">
          <v-col sm="6" md="4" align-self="stretch">
            <Login header="Acceso a tu Perfil" />
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
// import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "home-item",
  components: {
    Login,
    // Footer,
    Header,
  },
};
</script>

<style scoped>
.background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
</style>