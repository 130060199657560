<template>
  <div>
    <v-app-bar :clipped-left="true" app clipped-right color="white">
      <v-app-bar-nav-icon
        v-if="drawer.login_status && menuLeft"
        color="secondary"
        @click.stop="handleOpenSidebar"
        class="mr-5"
      />
      <div v-if="drawer.login_status">
        <v-btn
          icon
          @click="onClickHome()"
          :disabled="
            $router.currentRoute.name == 'Home' ||
            $router.currentRoute.name == 'Administration' ||
            $router.currentRoute.name == 'Manufacture'
          "
        >
          <v-icon color="logo" size="35"> mdi-home </v-icon>
        </v-btn>
      </div>
      <v-col
        class="pb-2"
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        offset-xl="3"
        offset-lg="3"
        offset-md="3"
        offset-sm="3"
        offset="3"
      >
        <v-row>
          <v-col cols="4" sm="4" md="4" lg="4" xl="4">
            <v-toolbar-title>
              <strong>Mhaya</strong>
            </v-toolbar-title>
          </v-col>
          <v-col
            v-if="drawer.login_status"
            class="pl-8"
            cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >
            <form
              ref="form"
              action="/users/logout?next=/"
              method="post"
              class="pr-4 d-flex flex-wrap align-content-end justify-end"
            >
              <a class="logo--text" @click.stop="submit">
                <v-icon class="logo--text">mdi-arrow-expand-right</v-icon>
                <span v-if="drawer.login_status">Logout</span>
              </a>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-navigation-drawer
      v-if="drawer.login_status && menuLeft"
      v-model="drawer.model"
      :clipped="true"
      :floating="false"
      :mini-variant="false"
      :permanent="false"
      :temporary="false"
      app
      overflow
    >
      <v-list nav>
        <v-list-item-group active-class="white--text logo">
          <template v-for="section in routes">
            <v-list-item
              v-if="!section.children"
              :key="section.name + '-item'"
              :to="section.path"
            >
              <v-list-item-icon>
                <v-icon color="__secondary">
                  {{ section.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ section.name }}
              </v-list-item-title>
            </v-list-item>
            <v-subheader v-else :key="section.name + '-subheader'">
              <v-icon color="logo">
                {{ section.icon }}
              </v-icon>
              <v-list-item-title>
                {{ section.name }}
              </v-list-item-title>
            </v-subheader>
            <v-list-item
              v-for="route in section.children"
              :key="section.path + '/' + route.path"
              dense
              :to="section.path + '/' + route.path"
            >
              <v-list-item-icon>
                <v-icon color="secondary">
                  {{ route.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ route.meta.button }}
              </v-list-item-title>
            </v-list-item>
            <v-divider :key="section.name + '-divider'" />
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// @ is an alias to /src
import { pages } from "@/router/index";
import Login from "@/components/Login";

export default {
  name: "header-item",
  components: {},
  data: () => ({
    drawer: {
      model: false,
      login_status: null,
    },
    routes: [],
  }),
  props: {
    menuLeft: Boolean,
  },
  created() {
    Login.check().then((login_status) => {
      this.drawer.login_status = login_status;
    });
    this.routes = this.getRoutes();
  },
  methods: {
    handleOpenSidebar() {
      this.drawer.model = !this.drawer.model;
      return this.drawer.model;
    },
    onClickHome: function () {
      let role = this.$store.state.role;
      if (role == "Mhaya") {
        this.$router.push("/administration");
      } else if (role == "Operario") {
        this.$router.push("/manufacture");
      } else {
        this.$router.push("/home");
      }
    },
    submit: function () {
      this.$store.commit("setAuthentication", false);
      this.$store.commit("logout");
      this.$refs.form.submit();
    },
    getRoutes() {
      let role = this.$store.state.role;
      let navigation = [];
      let navigationRoutes = [];

      if (role == "Mhaya") {
        navigation = ["Planning"];
      } else if (role == "Operario") {
        navigation = ["Operator"];
      } else {
        navigation = ["Datalogger", "Optimizer"];
      }

      for (let i in pages) {
        if (navigation.includes(pages[i].name)) {
          navigationRoutes.push(pages[i]);
        }
      }
      return navigationRoutes;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>