
<template>
  <v-card :loading="loading">
    <v-card-text class="justify-center">
      <h2>
        <strong>
          {{ title }}
        </strong>
      </h2>
    </v-card-text>
    <v-card-actions>
      <v-btn block color="logo" class="white--text" @click="$emit('clickButton')"> Access </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "title-button-card",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
};
</script>
<style scoped>
.v-card__title {
}
</style>