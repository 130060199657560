import LoadingCard from "@/components/LoadingCard.vue";

export function lazyLoad(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: LoadingCard,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 0,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}

// This functions invokes  func the first time it is called
// If called again before the wait period expires, schedules the call of the given
// call after wait period expires and calls loadingAction to allow you to notify the user
// If called over and over again, the function is never jnvoked a second time
// If called with different arguments, it generates an independent timer.
// This means that calling the same function with a changing value doesn't actually work
export function debounceLoading(loadingAction, func, wait) {
  var caller = {};
  return function () {
    var context = this,
      args = arguments;
    var key = JSON.stringify(args);
    if (!(key in caller)) {
      caller[key] = {
        timeout: null,
        later: null,
        calls: 0,
      };
    }
    caller[key].later = function () {
      if (caller[key].calls > 1) {
        func.apply(context, args);
        caller[key].calls = 0;
        caller[key].timeout = setTimeout(caller[key].later, wait);
      } else {
        caller[key] = {
          timeout: null,
          later: null,
          calls: 0,
        };
      }
    };

    if (caller[key].calls == 0) {
      func.apply(context, args);
    } else if (caller[key].calls == 1) {
      loadingAction.apply(context, args);
    }
    caller[key].calls += 1;
    clearTimeout(caller[key].timeout);
    caller[key].timeout = setTimeout(caller[key].later, wait);
  };
}

export function buildQuery(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .filter((k) => params[k])
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");
}

export function buildQueryList(params) {
  const esc = encodeURIComponent;

  const queryString = Object.keys(params)
    .filter((key) => {
      const value = params[key];

      // Filtrar valores vacíos y listas vacías
      return (
        value !== undefined &&
        value !== null &&
        !(Array.isArray(value) && value.length === 0)
      );
    })
    .map((key) => {
      const value = params[key];

      // Manejar listas
      if (Array.isArray(value)) {
        return value.map((val) => esc(key) + "[]=" + esc(val)).join("&");
      }

      // Manejar valores simples
      return esc(key) + "=" + esc(value);
    })
    .join("&");

  return queryString;
}
