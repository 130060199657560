import store from "@/store";

var Login = {
  realCheck() {
    var context = this;
    return fetch("/users/auth", {
      credentials: "same-origin",
    }).then(async function (response) {
      context.lastCheck = false;
      if (response.ok) {
        var data = await response.json();
        //El usuario del Api no puede entrar a la App
        if (data.name != "Api") {
          context.logedUser = data.name;
          context.lastCheck = true;
          if (data.name == "MhayaAdmin") {
            store.commit("setUsername", data.name);
            store.commit("setRol", "Mhaya");
            store.commit("setAuthentication", true);
          } else if (data.name == "operario") {
            store.commit("setUsername", data.name);
            store.commit("setRol", "Operario");
            store.commit("setAuthentication", true);
          } else if (data.name == "Mhaya") {
            store.commit("setUsername", data.name);
            store.commit("setRol", "Mhaya");
            store.commit("setAuthentication", true);
          }
        }
      }
      return context.lastCheck;
    });
  },
  check() {
    if (this.lastCheck != null) {
      return Promise.resolve(this.lastCheck);
    }
    return this.realCheck();
  },
  lastCheck: null,
  logedUser: null,
};

export default Login;
