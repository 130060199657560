<template>
  <v-card
    :loading="loadingBar"
    elevation="20"
    enabled="false"
  >
    <template v-if="logedUser == null">
      <v-form
        :id="login_form_id"
        action="/users/login?next=/"
        method="post"
      >
        <v-toolbar
          color="white elevation-3"
          dark
          flat
        >
          <v-toolbar-title class="logo--text">
            {{ header }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Usuario"
            name="username"
            prepend-icon="mdi-face"
            type="text"
            :disabled="loading"
            color="logo"
          />

          <v-text-field
            label="Contraseña"
            name="password"
            prepend-icon="mdi-lock"
            type="password"
            :clearable="true"
            :disabled="loading"
            color="logo"
          />
        </v-card-text>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn
            class="white--text"
            color="logo"
            :disabled="loading"
            type="submit"
            :form="login_form_id"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-form>
    </template>
    <template v-else>
      <v-form
        :id="logout_form_id"
        action="/users/logout?next=/"
        method="post"
      >
        <v-card-title class="justify-center">
          <h3>Bienvenido {{ logedUser }}</h3>
        </v-card-title>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn
            color="logo"
            :disabled="loading"
            type="submit"
            :form="logout_form_id"
          >
            Logout
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-form>
    </template>
  </v-card>
</template>

<script>

import Login from "./Login.js";


export default {
  name: "login-item",
  props: {
    // is the title displayed in the form
    header: {
      type: String,
      default: "Login form"
    },
    // contains the values of the form, you can use this property to read them or to assign a default value
    value: {
      type: Object,
      default: function () {
        return {
          user: null,
          pass: null
        };
      }
    },
  },
  data: function () {
    return {
      loading: true,
      logedUser: null,
      login_form_id: "user-login-form",
      logout_form_id: "user-logout-form"
    };
  },
  computed: {
    loadingBar: function () {
      return this.loading ? "logo" : false;
    }
  },
  watch: {
    value () {
      console.log(this.value.user)
    }
  },
  created () {
    Login.realCheck()
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        this.loading = false
        this.logedUser = Login.logedUser
      })
  },
};
</script>

<style scoped></style>