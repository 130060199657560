<template>
  <div>
    <Header :menuLeft="true" />

    <v-content>
      <router-view />
    </v-content>

    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "App",

  components: {
    // Footer,
    Header,
  },
};
</script>
