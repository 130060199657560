import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex);


const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
    // webSocketPlugin,
  ],
  state: {
    authenticated: false,
    username: null,
    role: null,
  },
  mutations: {
    setAuthentication(state, status) {
      state.authenticated = status;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setRol(state, role) {
      state.role = role;
    },
    logout(state) {
      state.authenticated = false;
      state.username = null;
      state.role = null;
    },
  },
  actions: {},
  modules: {},
  strict: process.env.NODE_ENV !== "production",
});

export default store;
